body {
  background: #e7ebf0;
}

.form {
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 50px;
  margin-top: 30px;
  border-radius: 10px;
  align-items: center;
}

.form .form__form-button {
  margin-top: 50px;
}

.form .form-title {
  font-size: 25px;
  color: #000000;
}

.form .form_link {
  align-items: end;
  color: yellow;
  font-size: xx-large;
}
